<template>

   <div class="container main-body">
      <div class="row faq">
        <div class="col-sm-10">
          <h4>Contact Us</h4>
        </div>

        <div class="col-sm-12 col-lg-6 mt-4" v-if="isSubmitted">
            <p>Successfully sent. Thank you for getting in touch.</p>
        </div>

        <div class="col-sm-12 col-lg-6 mt-4 justify-content-md-center" v-if="!isSubmitted">
          <form>
            <div class="form-group">
              <label for="exampleInputName1">Name (optional)</label>
              <input v-model="name" type="text" class="form-control" id="exampleInputName1" aria-describedby="nameHelp" placeholder="Enter name">
            </div>

            <div class="form-group mt-3" id="credit-device">
              <label for="thresholdSize">Subject</label><!--<i class="bi bi-info-circle" data-toggle="tooltip" data-placement="top" title=""></i>-->
              <select v-model="subject" class="form-control form-control-lg" id="dispute-device">
                <option value="select">Select</option>
                <option value="sales">Support</option>
                <option value="sales">Feedback</option>
                <option value="bug">Report a bug</option>
                <option value="referal">Referal Program</option>
                <option value="sales">Sales</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div class="form-group">
              <label for="exampleFormControlTextarea1">Message <span class="text-danger font-weight-bold">*</span></label>
              <textarea v-model="message" class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
            </div>

            <div v-if="!message || (subject === 'select')" class="form-group mt-5">
              <a @click="$event.preventDefault();" href="#" class="btn btn-orange-disabled btn-lg btn-block" data-toggle="modal" data-target="#creditModal">Submit</a>
            </div>

            <div v-else class="form-group mt-5">
              <a @click="$event.preventDefault(); submitForm();" href="#" class="btn btn-orange btn-lg btn-block" data-toggle="modal" data-target="#creditModal">Submit</a>
            </div>
          </form>
        </div>

      </div><!--END ROW FAQ -->
    </div>
</template>

<script>

export default {
   name: "Contact",
   data() {
      return {
         name: "",
         subject: "select",
         message: "",
         isSubmitted: false,
      };
   },
   mounted() {
   },
   methods: {
      submitForm() {
         window.gtag("event", "ContactFormSubmit");
         let postData = {
            name: this.name,
            subject: this.subject,
            message: this.message,
         };
         this.axios
            .post("/api/support-contact-us", postData, { withCredentials: true })
            .then(() => {
               this.isSubmitted = true;
            })
            .catch((error) => console.error(error));
      }
   }
}
</script>
